/* import { useMediaQuery, useTheme } from "@mui/material"; */
import { Box, Container } from "@mui/material";
import { RootSubscriptionHandlerState, secureSessionStorage, useAuthCompanyStore, useLoaderStore, useSubscriptionHandlerStore, useSubscriptionStore, /* usePermission */ } from "@sicarx/sxw-api";
import {
    CompleteTransaction, PagoConTarjeta,/* , SubscriptionDialog */
    SicarDialog
} from "@sicarx/sxw-components";
import { TNamespace, TSUBSCRIPTION, jwt_decode } from "@sicarx/sxw-core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
/* import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'; */
type ISubscribe = {
    planId: number
    amount: number
    isSubscribe: boolean
    transactionId: string
    paymentOption: string
    nextChargeDate: string
}

interface IFromMobileData {
    branchId?: string | null,
    locale?: string | null,
    timezone?: string | null,
    jwt?: string | null,
    xStatus?: string | null,
    xPayment?: string | null,
    companyIdentifier?: string | null,
}

const PARAMS = [ 
    "tmpAuth",
    "tmpLocale",
    "tmpTimezone",
    "x-status",
    "x-payment",
]
//http://localhost:3000/didCompleteSubscription?tmpLocale=es-MX&tmpTimezone=America/MexicoCity&tmpAuth=eyJhbGciOiJIUzUxMiIsInQiOjJ9.eyJiIjo4MDQsImMiOiJiNDA4NmE2YS1kMDc4LTQ2ZDktYjk3Ny1jM2M2OTI3NzRlNmEiLCJleHAiOjE3MDQ1MDAyNDMsImkiOiJub25lIiwiaWF0IjoxNzA0NDcxNDQzLCJqdGkiOiJpdmFuQHNpY2FyLm14IiwibiI6IjMiLCJwIjoiQURNSU4iLCJyIjoiNjkwNjI3MWQtZWM4NS00OGRmLWJkYTgtNTRjNWFkNDU5NGRlIiwidSI6Ijc3MjhiYTc2LTI0ZjMtNDBhNS04ODA2LTQzZGJmZDBjZWE0YSIsInVhIjoiQURNSU4iLCJ4Ijo2MTB9.e8lXQ78YHDPdGDCrmFoluQQWyKD233vuhOTMM2vGkY6txZXcEUm0rdIUPkMhXIBAtvmz3E56f6EeMIqXR9aGaA&tmpBranchId=804&tmpUuid=b4086a6a-d078-46d9-b977-c3c692774e6a
// http://localhost:3000/didCompleteSubscription?tmpLocale=es-MX&tmpTimezone=America/MexicoCity&tmpAuth=eyJhbGciOiJIUzUxMiIsInQiOjJ9.eyJiIjo4MDQsImMiOiJiNDA4NmE2YS1kMDc4LTQ2ZDktYjk3Ny1jM2M2OTI3NzRlNmEiLCJleHAiOjE3MDQ5MzAxNzUsImkiOiJub25lIiwiaWF0IjoxNzA0OTAxMzc1LCJqdGkiOiJpdmFuQHNpY2FyLm14IiwibiI6IjMiLCJwIjoiQURNSU4iLCJyIjoiNjkwNjI3MWQtZWM4NS00OGRmLWJkYTgtNTRjNWFkNDU5NGRlIiwidSI6Ijc3MjhiYTc2LTI0ZjMtNDBhNS04ODA2LTQzZGJmZDBjZWE0YSIsInVhIjoiQURNSU4iLCJ4Ijo2MTB9.qFDyaBEHJZRQBWoz6gabwPl-REg9CKLTBBijIrU7mQj2dba0cgbk5oBVrUHSlCbBiwohCgkvNl5q2fQOzyafAA&tmpBranchId=804
export const PaymentComponent = (() => {
    const navigator = useNavigate();
    const [isMounted, setIsMounted] = useState(false);
    const { t } = useTranslation(TNamespace.SUBSCRIPTION)

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const allParamsFullfilled = PARAMS.every(param => param === 'x-payment' || searchParams.has(param))

        if (!allParamsFullfilled) {
            navigator("/dashboard", { replace: true })
            PARAMS.forEach(param => secureSessionStorage.removeItem(param))
            return
        }

        PARAMS.forEach(param => secureSessionStorage.setItem(param, searchParams.get(param)))
        setIsMounted(true);
    }, []);

    return isMounted ? <Wrapper /> : null
})

const Wrapper = (() => {
    const getPlans = useSubscriptionHandlerStore((store: RootSubscriptionHandlerState) => store.getPlans);
    const [plansResponse, setPlansResponse] = useState<any>([]);
    const { t } = useTranslation();
    const getCards = useSubscriptionStore(store => store.getCards);
    const cards = useSubscriptionStore(store => store.subscription.cards);
    const showLoader = useLoaderStore(store => store.showLoader);
    const loader = useLoaderStore(store => store.loader);
    const [isLoadingPlans, setIsLoadingPlans] = useState(false);
    const [subscribe, setSubscribe] = useState<ISubscribe | null>({
        planId: 0,
        amount: 0,
        isSubscribe: false,
        transactionId: '',
        paymentOption: '',
        nextChargeDate: ''
    });
    const [tmpData,] = useState<IFromMobileData>(
        {
            //@ts-ignore
            companyIdentifier: jwt_decode(secureSessionStorage?.getItem("tmpAuth"))?.c ?? '',
            //@ts-ignore
            branchId: jwt_decode(secureSessionStorage?.getItem("tmpAuth"))?.b ?? '',
            locale: secureSessionStorage?.getItem("tmpLocale"),
            timezone: secureSessionStorage?.getItem("tmpTimezone"),
            jwt: secureSessionStorage?.getItem("tmpAuth"),
            xStatus: secureSessionStorage?.getItem("x-status"),
            xPayment: secureSessionStorage?.getItem("x-payment") ?? null,
        }
    );

    useEffect(() => {
        (async () => {
            setIsLoadingPlans(true)
            showLoader(true)
            setPlansResponse(await getPlans())
            await getCards(secureSessionStorage?.getItem("tmpAuth"));
            showLoader(false)
            setIsLoadingPlans(false)
        })()
    }, [])

    return <>
        <SicarDialog
            open={true}
            title={t(TSUBSCRIPTION.CARD_PAYMENT, { ns: 'subscription' })}
            fullScreen
            PaperProps={{
                sx: {
                    width: "100%",
                    height: "100%",
                    // maxWidth: "900px",
                    transition: 'ease .5s',
                    backgroundColor: "background.default",
                }
            }}
        >
            <Container sx={{
                height: 'calc(100% - 64px)',
                display: 'flex',
                transition: 'ease .5s',
                flexDirection: 'column',
                maxHeight: {
                    md: 'calc(100% - 64px)',
                    sm: '100%'
                },
                px: {
                    md: '100px',
                    sm: '24px',
                    xs: '24px'
                }
            }}>
                <>
                    {isLoadingPlans
                        ? <></>
                        : subscribe?.isSubscribe
                            ? <CompleteTransaction fullScreen {...subscribe} branchId={Number(tmpData.branchId)} aditionalInfo={`${t(TSUBSCRIPTION.CANCLOSEWINDOW_MSG, { ns: TNamespace.SUBSCRIPTION })}`} />
                            : <PagoConTarjeta plans={plansResponse} buyNewBranch={false} setSubscribe={setSubscribe} fromMobile={tmpData} />
                    }
                </>

            </Container>
        </SicarDialog>
    </>
})