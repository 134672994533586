import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App';
import { CustomSnackProvider } from "@sicarx/sxw-components"
import { configI18n } from "@sicarx/sxw-core"; configI18n();
import { BrowserRouter } from 'react-router-dom';
import { WithAxios } from '@sicarx/sxw-api';
/* import { initSentry } from "./sentry" */

if (location.hostname.includes("localhost") && (location.port === "49152" || location.port === "49153" || location.port === "49154")) {
  import('i18next').then(({ default: m }) => {
    console.log("language", m.language)
    fetch(`${location.origin}/api/updater/setLanguage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ lang: m.language })
    })/* .then(response => response.json())
      .then(data => console.log("dataResponse", data)); */
  })
}
/* initSentry() */
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <CustomSnackProvider>
      <BrowserRouter>
        <WithAxios>
          <App />
        </WithAxios>
      </BrowserRouter>
    </CustomSnackProvider>
  </StrictMode>
)